<template>
 <div> 
    <mt-header  class="header-title">
      <div slot="left" @click="back"  class="row"><i class="el-icon-back"></i><span class="back">Back</span></div>
    </mt-header>
    <div class="create-contact row">
      <div class="create-contact-title">{{ title }}</div>
      <div class="create-infor" v-loading="loading">
        <el-form :model="data" ref="data" :rules="rules">
          <div class="row infor-row owner-row" v-if="this.$route.params.id">
            <div class="row-item" >
              <el-form-item>
                <p class="form-title">Contact Owner</p>
                <el-select v-model="data.user" placeholder>
                  <el-option
                    v-for="(item, index) in owners"
                    :key="index"
                    :value="item.user_id"
                    :label="item.first_name + ' ' + item.last_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item v-if="this.$route.params.id&&(owner_id!=data.user)">
                <el-input
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 2}"
                  placeholder="Reason"
                  v-model="data.reason">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row infor-row">
            <div class="row-item">
              <el-form-item prop="types">
                <p class="form-title">Contact Type(s)</p>
                <el-select v-model="data.types" multiple placeholder="Select">
                  <el-option
                    v-for="item in typesData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="row-item" style="margin-top: 20px">
              <el-form-item>
                <el-checkbox v-model="checked" class="check"
                  >Opt-out of Markeing Emails</el-checkbox
                >
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">First Name</p>
                <el-input v-model="data.first_name"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item prop="last_name">
                <p class="form-title">Last Name or Entity Name</p>
                <el-input v-model="data.last_name"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item prop="email1" :rule="rules.email1">
                <p class="form-title">Email Address</p>
                <el-input v-model="data.email1"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item prop="email2" :rule="rules.email2">
                <p class="form-title">Email Address 2</p>
                <el-input v-model="data.email2"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">Cell Phone</p>
                <el-input
                  v-model="data.cell_phone"
                  maxlength="22"
                  @input="(e) => (data.cell_phone = changePhone(e))"
                ></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
                <el-form-item>
                  <p class="form-title">Home Phone</p>
                  <el-input
                    v-model="data.home_phone"
                    maxlength="22"
                    @input="(e) => (data.home_phone = changePhone(e))"
                  ></el-input>
                </el-form-item>
              </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">Work Phone</p>
                <el-input
                  v-model="data.work_phone"
                  maxlength="22"
                  @input="(e) => (data.work_phone = changePhone(e))"
                ></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">Street Address</p>
                <el-input v-model="data.street1"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">Street Address 2</p>
                <el-input v-model="data.street2"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">City</p>
                <el-input v-model="data.city"></el-input>
              </el-form-item>
            </div>
            <div class="row-item">
              <div class="row small-item">
                <div class="row-item">
                  <el-form-item>
                    <p class="form-title">State</p>
                    <el-input v-model="data.state"></el-input>
                  </el-form-item>
                </div>
                <div class="row-item">
                  <el-form-item class="demo-form-inline">
                    <p class="form-title">Zip Code</p>
                    <el-input v-model="data.zip_code"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row-item">
              <el-form-item>
                <p class="form-title">Country</p>
                <el-input v-model="data.country"></el-input>
              </el-form-item>
            </div>
            <div class="row-item"></div>
          </div>
          <div class="add-secondary" v-if="show === false">
            <a @click="showSelect"> + Add Secondary Contact</a>
          </div>
          <div v-if="show == true">
            <p class="secondary-contact">Secondary Contact</p>
            <p style="margin: 10px 0 20px 0; text-align: left">
              <el-checkbox v-model="data.use_sec_name_on_lease" class="check">Use Secondary Contact Name on Leases</el-checkbox>
            </p>
            <div class="row infor-row">
              <div class="row-item">
                <el-form-item prop="sec_first_name">
                  <p class="form-title">First Name</p>
                  <el-input v-model="data.sec_first_name"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item prop="sec_last_name">
                  <p class="form-title">Last Name or Entity Name</p>
                  <el-input v-model="data.sec_last_name"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item prop="sec_email1">
                  <p class="form-title">Email Address</p>
                  <el-input v-model="data.sec_email1"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item prop="sec_email2" :rule="rules.email2">
                  <p class="form-title">Email Address 2</p>
                  <el-input v-model="data.sec_email2"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item>
                  <p class="form-title">Cell Phone</p>
                  <el-input
                    v-model="data.sec_cell_phone"
                    maxlength="22"
                    @input="(e) => (data.sec_cell_phone = changePhone(e))"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item>
                  <p class="form-title">Home Phone</p>
                  <el-input
                    v-model="data.sec_home_phone"
                    maxlength="22"
                    @input="(e) => (data.sec_home_phone = changePhone(e))"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item>
                  <p class="form-title">Work Phone</p>
                  <el-input
                    v-model="data.sec_work_phone"
                    maxlength="22"
                    @input="(e) => (data.sec_work_phone = changePhone(e))"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="row-item">
                <el-form-item>
                  <p class="form-title">Street Address</p>
                  <el-input v-model="data.sec_street1"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item>
                  <p class="form-title">Street Address 2</p>
                  <el-input v-model="data.sec_street2"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <el-form-item>
                  <p class="form-title">City</p>
                  <el-input v-model="data.sec_city"></el-input>
                </el-form-item>
              </div>
              <div class="row-item">
                <div class="row small-item">
                  <div class="row-item">
                    <el-form-item>
                      <p class="form-title">State</p>
                      <el-input v-model="data.sec_state"></el-input>
                    </el-form-item>
                  </div>
                  <div class="row-item">
                    <el-form-item class="demo-form-inline">
                      <p class="form-title">Zip Code</p>
                      <el-input v-model="data.sec_zip_code"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row-item">
                <el-form-item>
                  <p class="form-title">Country</p>
                  <el-input v-model="data.sec_country"></el-input>
                </el-form-item>
              </div>
              <div class="row-item"></div>
            </div>
          </div>
        </el-form>
        <div class="btn">
          <el-button class="" @click="cancel">Clear</el-button>
          <el-button class="primary-button" @click="save('data')">Save</el-button>
        </div>
      </div>
    </div>
</div>
  
</template>
<script>
import { apiCreateContact, apiGetContact, apiUpdateContact, apiGetUsers,} from "../../../API/api";
import { Minixs } from "../../../js/mixins";
export default {
  mixins: [Minixs],
  data() {
    return {
      loading: false,
      secondaryName: true,
      show: false,
      checked: true,
      title: "New Contact",
      typesData: [
        {
          value: "tenant",
          label: "Tenant",
        },
        {
          value: "buyer",
          label: "Buyer",
        },
        {
          value: "seller",
          label: "Seller",
        },
        {
          value: "homeowner",
          label: "Homeowner",
        },
      ],
      data: {
        // reason:"",
        types: [],
        first_name: "",
        last_name: "",
        cell_phone: "",
        home_phone:"",
        work_phone: "",
        country: "",
        email2: "",
        email1: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip_code: "",
        use_sec_name_on_lease: false,
        sec_first_name: "",
        sec_last_name: "",
        sec_cell_phone: "",
        sec_home_phone: "",
        sec_work_phone: "",
        sec_country: "",
        sec_email2: "",
        sec_email1: "",
        sec_street1: "",
        sec_street2: "",
        sec_city: "",
        sec_state: "",
        sec_zip_code: "",
      },
      owners: [],
      rules: {
        types: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
        ],
        last_name: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
        ],
        sec_last_name: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
        ],
        email1: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur","change"],
          },
        ],
        sec_email1: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur","change"],
          },
        ],
        email2: [
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur","change"],
          },
        ],
      },
      owner_id:"",
    };
  },
  methods: {
    back(){
      if (this.$route.params.id) {
        this.$router.push("/contact/" + this.$route.params.id);
      } else {
        this.$router.go(-1);
      }
    },
    showSelect() {
      this.show = true;
    },
    // 获取租客的详细信息
    getContactDetail() {
      apiGetContact(this.$route.params.id).then((res) => {
        this.data = res;
        this.owner_id = res.user
        if (res.sec_first_name || res.sec_last_name || res.sec_email1) {
          this.show = true;
        }
      });
    },
    getAgentList() {
      // 获取pm用户列表
      apiGetUsers({
        user_type: ["pm", "admin"],
      }).then((res) => {
        this.owners = res.results;
      });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateContact(this.$route.params.id, this.data)
              .then((res) => {
                this.$router.push({
                  name: "show-contact",
                  params: { id: res.contact_id },
                });
                this.$message.success(
                  "Contact information updated successfully"
                );
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err.response.data.detail);
              });
          } else {
            // 创建
            apiCreateContact(this.data)
              .then((res) => {
                // 跳转到修改页面，获取代理人列表
                this.data = res;
                this.getAgentList();
                this.$router.push({
                  name: "show-contact",
                  params: { id: res.contact_id },
                });
                this.$message.success("Contact created successfully");
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err.response.data.detail);
                
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
          // return false;
        }
      });
    },
    cancel() {
      this.data.types = [];
      this.data.cell_phone = this.data.home_phone = this.data.work_phone = this.data.sec_cell_phone = this.data.sec_home_phone = this.data.sec_work_phone = null;
      this.data.sec_country = this.data.country = this.data.email2 = this.data.email1 = this.data.street1 = this.data.street2 = this.data.city = this.data.state = this.data.zip_code = this.data.last_name = this.data.first_name = this.data.sec_email2 = this.data.sec_email1 = this.data.sec_street1 = this.data.sec_street2 = this.data.sce_city = this.data.sec_state = this.data.sec_zip_code = this.data.sec_last_name = this.data.sec_first_name =
        "";
    },
  },
  created() {
    this.getAgentList();
    if (this.$route.params.id) {
      this.title = "Edit Contact";
      this.getContactDetail();
    } else {
      this.title = "New Contact";
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.header-title{
  color: #678993;
  background: none;
  margin: 20px 0 0px 0;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  display: none;
  .back{ margin-left: 15px;}
  @media (max-width:992px) {
    display: block;
  }
}
.create-contact {
  background-color: #fff;
  padding: 20px 20px 100px 20px;
  align-items: flex-start;
  .create-contact-title {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 22px;
    color: #38425b;
    margin-right: 70px;
  }
  .create-infor {
    margin-top: 65px;
    max-width: 740px;
    width: calc(100% - 100px);
    /deep/ .el-checkbox {
      color: #38425b;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #678993;
        border-color: #678993;
      }
      .el-checkbox__label {
        color: #38425b;
      }
    }
    .infor-row {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      .row-item {
        width: calc(calc(100% - 20px) / 2);
        text-align: left;
        .form-title {
          line-height: 26px;
          font-size: 14px;
          margin: 0;
          text-align: left;
        }
        /deep/ .el-form-item {
          margin-bottom: 10px;
        }
        /deep/ .el-select,
        /deep/ .el-input {
          width: 100%;
        }
        &:nth-last-of-type(2n) {
          margin-left: 20px;
        }
      }
      .small-item .row-item{margin-left: 0;&:last-of-type{margin-left: 20px;}} 
    }
    .owner-row {
      align-items: flex-end;
      .row-item{
      &:first-of-type{
          margin-left: 0px;
      }
      &:last-of-type{
        margin-left: 20px;
      }
    }}
    .secondary-contact {
      text-align: left;
      margin: 10px 0 0 0;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 16px;
    }
    .add-secondary {
      text-align: left;
      a {
        font-size: 14px;
        font-family: "Roboto-Bold", sans-serif;
        color: #678993;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .btn {
    text-align: right;
    .el-button {
      width: 100px;
    }
  }
  @media (max-width: 992px) {
    display: block;
    .create-contact-title{
      text-align: left;
    }
    .create-infor {
      width: 100%;
      margin-top: 10px;
      .infor-row {
      display: block;
      .row-item {
        width: 100%;
        &:nth-last-of-type(2n) {
          margin-left: 0px;
        }
      }
    }
    .small-item{
      display: block;
    }
    }
  }
}
</style>